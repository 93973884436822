<template>
  <div
    class="Users"
  >
    <AppBarLayout
      class="Users__app-bar"
      action-buttons-class="ml-6"
      no-spacer
    >
      <template #header>
        {{ $t('user.UserList') }}
      </template>

      <template #actions>
        <v-btn
          color="primary"
          depressed
          :to="createUserRoute"
          replace
          exact
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-plus'"
          />
          {{ $t('user.User') }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          :to="createGroupRoute"
          replace
          exact
          class="ml-2"
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-plus'"
          />
          {{ $t('userGroup.Group') }}
        </v-btn>
      </template>
    </AppBarLayout>

    <CommonDataTable
      v-if="filteredUsersOrBots != null"
      v-model="selectedUserIds"
      class="Users__table"
      :headers="headers"
      :items="filteredUsersOrBots || []"
      plain-styles
      show-actions-row
      sort-by="user.userLogin"
      size="lg"
      :scrollable-height="`calc(100vh - ${$vuetify.breakpoint.mdAndUp ? 64 : 56}px - 9px)`"
      fixed-header
      :no-data-text="$t('user.NoUsersMatchingM')"
      resizeable-columns
      local-storage-key="Users__table"
      :show-select="checkIsUser"
      checkbox-color="primary"
    >
      <template #actions>
        <div class="px-8 d-flex align-center flex-grow-1">
          <CommonDataTableCounter items-message="user.usersP" />

          <CommonTextField
            v-model="searchModel"
            hide-details
            margins-with-hidden-details="mb-0"
            outlined
            dense
            class="mx-4"
            :placeholder="$t('user.NameOrEmail')"
          />

          <span
            class="textSecondary--text font-weight-medium"
            style="min-width: 50px"
            v-text="$t('layout.ShowColon')"
          />

          <v-btn-toggle
            v-model="tableModeIndexModel"
            mandatory
            dense
            tile
            group
            color="primary"
          >
            <v-btn
              v-for="tableMode in TABLE_MODES"
              :key="tableMode.value"
              class="pr-4 ml-1 mr-0 rounded"
              :class="tableMode.icon ? 'pl-2' : 'pl-4'"
              :ripple="{ class: 'app-ripple' }"
            >
              <v-icon
                v-if="tableMode.icon"
                class="mr-1"
                :color="tableModeValue === tableMode.value ? 'primary' : '#666999'"
                v-text="tableMode.icon"
              />
              {{ $t(tableMode.label) }}
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>

      <template #item.user.userLogin="{ item: userOrBot }">
        <UserAvatar
          :user="userOrBot.user"
          :icon="userOrBot.isBot ? 'mdi-robot-outline' : null"
          class="mr-4"
        />
        <div class="overflow-hidden">
          <div>
            {{ userOrBot.user.firstName }}
            {{ userOrBot.user.lastName }}
            <template
              v-if="!(userOrBot.user.firstName || '').trim() &&
                !(userOrBot.user.lastName || '').trim()"
            >
              {{ userOrBot.user.userLogin }}
            </template>
          </div>
          <div
            v-if="userOrBot.isBot"
            class="textSecondary--text"
            v-text="$t('user.botUser')"
          />
          <div
            v-else
            class="textSecondary--text"
            v-text="[userOrBot.user.userLogin, userOrBot.user.userEmail].join(' ')"
          />
        </div>
      </template>

      <template #item.user.role="{ value: role, item: userOrBot, hover }">
        <span
          v-if="userOrBot.isBot"
          class="d-inline-flex align-center"
        >
          <v-icon
            color="iconPrimary"
            class="mr-2"
            v-text="getRoleIcon(role)"
          />
          <span
            class="textPrimary--text"
            v-text="getRoleDisplayName(role)"
          />
        </span>
        <v-menu v-else>
          <template #activator="{ on, attrs }">
            <span
              :ref="`item-${userOrBot.id}-role-menu-btn`"
              v-bind="attrs"
              class="d-inline-flex align-center text-truncate"
              @click.stop.prevent
              v-on="on"
            >
              <v-icon
                :color="hover ? 'primary' : 'iconPrimary'"
                class="mr-2"
                v-text="getRoleIcon(role)"
              />
              <span
                class="text-truncate"
                :class="hover ? 'primary--text' : 'textPrimary--text'"
                v-text="getRoleDisplayName(role)"
              />
            </span>
          </template>

          <v-list
            dense
            class="py-0"
          >
            <v-list-item
              v-for="roleItem in userRoles"
              :key="roleItem.name"
              style="padding: 0 14px"
              :input-value="roleItem.name === userOrBot.user.role"
              @click="setRole(userOrBot, roleItem.name)"
            >
              <v-icon
                class="mr-2"
                v-text="roleItem.icon"
              />
              <v-list-item-title>{{ $t(`user.${roleItem.displayName}`) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #item.user.state="{ value: state, item: userOrBot }">
        <v-menu v-if="!userOrBot.isBot">
          <template #activator="{ on, attrs }">
            <div
              :ref="`item-${userOrBot.id}-state-menu-btn`"
              v-bind="attrs"
              @click.stop.prevent
              v-on="on"
            >
              <v-icon
                :size="24"
                :color="USER_STATE[state].iconColor"
                v-text="USER_STATE[state].icon"
              />
            </div>
          </template>

          <v-list
            dense
            class="py-0"
          >
            <v-list-item
              v-for="userState in userStateItems(userOrBot.user)"
              :key="userState.value"
              style="padding: 0 14px"
              :input-value="userState.value === state"
              @click="setState(userOrBot.user, userState.value)"
            >
              <v-icon
                class="mr-2"
                :color="userState.iconColor"
                v-text="userState.icon"
              />
              <v-list-item-title>{{ $t(userState.label) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #item.userGroupsDisplay="{ item }">
        <div
          v-if="!item.isBot"
          class="Users__user-groups"
        >
          <div
            v-if="item.user.userGroups.length"
            class="text-truncate"
            style="max-width: fit-content; height: 24px"
            @click.stop.prevent
            @mousedown.stop
            @touchstart.stop
          >
            <router-link
              :to="getEditGroupRoute(item.user.userGroups[0].id)"
              exact
              replace
              class="Users__user-group-link"
            >
              {{ item.user.userGroups[0].name }}
            </router-link>
          </div>
          <div
            v-if="item.user.userGroups.length > 1"
            style="max-width: fit-content"
            class="Users__user-group-link Users__user-group-link--more text-truncate mt-1"
            @click.stop.prevent
            @mousedown.stop
            @touchstart.stop
          >
            <v-menu>
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="patchListenersClickStopPrevent(on)"
                  v-text="$t('layout.more')"
                />
              </template>

              <v-list
                dense
                class="py-0"
              >
                <v-list-item
                  v-for="group in item.user.userGroups.slice(1)"
                  :key="group.id"
                  :to="getEditGroupRoute(group.id)"
                  exact
                  replace
                  :ripple="{ class: 'app-ripple' }"
                  class="px-3 primary--text"
                >
                  {{ group.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </template>

      <template #header._actions>
        <AppSwitch
          :key="switchKey"
          :reversed="false"
          :input-value="mfaRequired"
          :label="`${mfaRequired ? $t('layout.ON'): $t('layout.OFF')} ${$t('user.RequireCode')}`"
          @change="setGlobalMfaRequired($event).finally(() => ++switchKey)"
        />
      </template>
      <template #item._actions="{ item: userOrBot }">
        <div
          v-if="currentUser"
          class="d-flex align-center"
          @click.stop.prevent
          @mousedown.stop
          @touchstart.stop
        >
          <AppSwitch
            v-if="!userOrBot.isBot"
            :key="switchKey"
            :input-value="mfaRequired || userOrBot.user.mfaConfirmed"
            :disabled="
              (!userOrBot.user.mfaConfirmed && userOrBot.id !== currentUser.id) ||
                (mfaRequired && userOrBot.user.mfaConfirmed)"
            :reversed="false"
            :label="mfaRequired || userOrBot.user.mfaConfirmed ? `${$t('user.Mfa')} ${$t('layout.ON')}`: $t('layout.OFF')"
            @change="$event ? enableMfa(userOrBot.user) : disableMfa(userOrBot.user)"
          />

          <v-btn
            v-if="!userOrBot.isBot && userOrBot.user.mfaConfirmed"
            text
            plain
            tile
            color="primary"
            class="px-1 ml-8"
            @click="resetMfa(userOrBot.user)"
          >
            {{ $t('user.ResetCode') }}
          </v-btn>
        </div>
      </template>
    </CommonDataTable>

    <UserDialog
      :value="showUserDialog"
      :user-id="dialogUserId"
      :bot-id="dialogBotId"
      :password-change-required="changePassword"
      @input="!$event && closeDialog()"
    />
    <MfaSetupDialog
      :value="mfaDialog"
      v-bind="mfaDialogState"
      @success="mfaDialog = false"
      @input="(mfaDialog !== $event && ++switchKey), (mfaDialog = $event)"
    />

    <v-snackbar
      v-if="selectedUserIds.length > 0 && !anyDialogIsOpen"
      bottom
      :value="true"
      :timeout="-1"
      :height="48"
      :transition="false"
      color="#41415A"
      content-class="textPrimary--text pl-6"
    >
      <div class="d-flex flex-grow-1 align-center">
        <span
          style="font-size: 14px; line-height: 20px"
          v-text="$tc('layout.SelectedN', selectedUserIds.length)"
        />

        <v-btn
          text
          plain
          tile
          color="textPrimary"
          class="px-1 ml-8"
          :to="createGroupFromSelectionRoute"
          exact
          replace
        >
          <v-icon
            color="tertiary"
            class="mr-2"
            v-text="'mdi-account-multiple-plus-outline'"
          />
          {{ $t('userGroup.CreateGroup') }}
        </v-btn>

        <v-btn
          text
          plain
          tile
          color="textPrimary"
          class="px-1 ml-7"
          style="margin-right: 76px"
          @click="addSelectionToGroup"
        >
          <v-icon
            color="tertiary"
            class="mr-2"
            v-text="'mdi-location-enter'"
          />
          {{ $t('user.AddToExistingGroup') }}
        </v-btn>

        <CommonIconButton
          absolute
          right="24px"
          top="50%"
          style="transform: translateY(-50%)"
          icon="mdi-close"
          @click="selectedUserIds = []"
        />
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import * as R from 'ramda'

import { UserRoleValueSchema as USER_ROLE } from '@/api'
import { USER_STATE } from '@/constants'
import { wait, fmtDate, replaceRoute } from '@/helpers'

import Dialog from '@/store/orm/dialog'
import UserRole from '@/store/orm/userRole'

import AppBarLayout from '@/layouts/AppBarLayout.vue'
import UserDialog from '@/components/UserDialog'
import UserAvatar from '@/components/UserAvatar.vue'
import MfaSetupDialog from '@/components/MfaSetupDialog.vue'

const TABLE_MODE = Object.freeze({
  ALL: Object.freeze({
    value: 'ALL',
    label: 'layout.All',
    icon: null,
  }),
  USERS: Object.freeze({
    value: 'USERS',
    label: 'user.User',
    icon: 'mdi-account-outline',
  }),
  BOTS: Object.freeze({
    value: 'BOTS',
    label: 'user.Bot',
    icon: 'mdi-robot-outline',
  }),
})
const TABLE_MODES = Object.freeze([TABLE_MODE.ALL, TABLE_MODE.USERS, TABLE_MODE.BOTS])

export default {
  name: 'Users',

  components: {
    MfaSetupDialog,
    UserAvatar,
    AppBarLayout,
    UserDialog,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title('Users'),
    }
  },

  props: {
    showUserDialog: { type: Boolean, default: false },
    dialogUserId: { type: String, default: null },
    dialogBotId: { type: String, default: null },
    showGroupDialog: { type: Boolean, default: false },
    dialogGroupId: { type: String, default: null },
    searchQuery: { type: String, default: '' },
    // initialize (group) dialog with selected user ids
    fromSelection: { type: Boolean, default: false },
  },

  data() {
    return {
      USER_STATE,

      TABLE_MODE,
      TABLE_MODES,
      tableModeValue: TABLE_MODE.ALL.value,

      switchKey: Number.MIN_SAFE_INTEGER,
      mfaRequired: null,
      savingSystemSettings: false,
      selectedUserIds: [],

      dialogInstances: { group: null },

      mfaDialog: false,
      mfaDialogState: {
        userId: null,
        otpauthUrl: '',
        base32: '',
      },
    }
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },

    headers() {
      return [
        {
          text: this.$t('user.Name'),
          value: 'user.userLogin',
          width: 300,
          click: ({ editRoute }) => replaceRoute(this.$router, editRoute),
        },
        {
          text: this.$t('user.Created'),
          value: 'user.registered',
          displayValue: ({ user }) => user.registered && fmtDate(user.registered),
          width: 100,
        },
        {
          text: this.$t('user.Role'),
          value: 'user.role',
          click: ({ user, isBot }) => isBot
            ? this.$store.commit('$snackbar/setMessage', {
              message: this.$t('user.BotRoleIsReadonlyM'),
              bottom: true,
            })
            // It is not strictly needed, just increases the possible click area
            // to match the whole cell (better UX only)
            : this.$refs[`item-${user.id}-role-menu-btn`].click(),
          width: 132,
        },
        {
          text: this.$t('user.Status'),
          value: 'user.state',
          click: ({ user, isBot }) => isBot
            ? this.$store.commit('$snackbar/setMessage', {
              message: this.$t('user.BotDoesntHaveStatusM'),
              bottom: true,
            })
            : this.$refs[`item-${user.id}-state-menu-btn`].click(),
          tooltip: ({ user, isBot }) => isBot ? null : this.$t(USER_STATE[user.state].label),
          width: 100,
        },
        {
          text: this.$t('userGroup.Groups'),
          value: 'userGroupsDisplay',
          width: 100,
        },
        {
          text: '',
          value: '_actions',
          sortable: false,
          reorderable: false,
          width: 300,
        },
      ]
    },

    tableModeIndexModel: {
      get() {
        return TABLE_MODES.findIndex(mode => mode.value === this.tableModeValue) ?? 0
      },
      set(ix) {
        this.tableModeValue = TABLE_MODES[ix]?.value ?? TABLE_MODE.ALL.value
      },
    },

    searchModel: {
      get() { return this.searchQuery },
      set(q) {
        const newQuery = R.ifElse(
          () => !!q,
          R.assoc('q', q),
          R.omit(['q']),
        )(this.$route.query)
        replaceRoute(this.$router, { query: newQuery })
      },
    },

    searchTokens() {
      return (this.searchQuery || '')
        .trim()
        .split(/\s+?/g)
        .map(s => s.trim().toLocaleLowerCase())
        .filter(Boolean)
    },

    users() { return this.$store.getters['user/list'] },
    bots() { return this.$store.getters['botUser/list'] },
    userRoles() { return UserRole.getOrderedQuery().all() },

    usersOrBots() {
      const { $route, users, bots } = this
      return users && bots && [
        ...users.map(user => ({
          isBot: false,
          id: user.id,
          user,
          editRoute: {
            query: {
              ...R.omit(['action', 'userId', 'botId', 'groupId'], $route.query),
              action: 'edit-user',
              userId: user.id,
            },
          },
          // as a text for sorting
          userGroupsDisplay: (user.userGroups || []).map(g => g.name).join(', '),
          _searchBy: [
            user.firstName,
            user.lastName,
            user.userLogin,
            user.userEmail,
            user.id && String(user.id),
          ]
            .filter(Boolean)
            .map(s => s.toLocaleLowerCase())
            .join(' '),
        })),
        ...bots.map(bot => ({
          isBot: true,
          user: bot,
          id: bot.id,
          editRoute: {
            query: {
              ...R.omit(['action', 'userId', 'botId', 'groupId'], $route.query),
              action: 'edit-user',
              botId: bot.id,
            },
          },
          userGroupsDisplay: '',
          _searchBy: [
            bot.userLogin,
            bot.id && String(bot.id),
          ]
            .filter(Boolean)
            .map(s => s.toLocaleLowerCase())
            .join(' '),
        })),
      ]
    },
    filteredUsersOrBots() {
      const { tableModeValue, usersOrBots, searchTokens } = this
      if (!usersOrBots) return null
      const leaveBots = {
        [TABLE_MODE.USERS.value]: false,
        [TABLE_MODE.BOTS.value]: true,
      }[tableModeValue]
      const filteredByType = leaveBots == null
        ? usersOrBots
        : usersOrBots.filter(rec => rec.isBot === leaveBots)
      return searchTokens.length
        ? filteredByType.filter(user =>
          searchTokens.every(q =>
            user._searchBy.includes(q)))
        : filteredByType
    },

    systemSettings() {
      return this.$store.state.service.systemSettings
    },

    changePassword() {
      return this.$route.query.changePassword === 'true'
    },

    createUserRoute() {
      return {
        query: {
          ...R.omit(['userId', 'botId', 'groupId'], this.$route.query),
          action: 'create-user',
        },
      }
    },

    createGroupRoute() {
      return {
        query: {
          ...R.omit(['userId', 'botId', 'groupId'], this.$route.query),
          action: 'create-group',
        },
      }
    },

    createGroupFromSelectionRoute() {
      return {
        query: {
          ...R.omit(['userId', 'botId', 'groupId'], this.$route.query),
          action: 'create-group-from-selection',
        },
      }
    },

    closeModalRoute() {
      return {
        query: R.omit(['action', 'groupId', 'userId', 'botId']),
      }
    },

    anyDialogIsOpen() {
      const { showUserDialog, showGroupDialog } = this
      return showUserDialog || showGroupDialog ||
        Dialog.query().where('isOpen', true).exists()
    },
  },

  watch: {
    'systemSettings.mfaRequired': {
      handler(mfaRequired) { this.mfaRequired = mfaRequired ?? null },
      immediate: true,
    },
  },

  created() {
    this.fetchUsers()
    this.fetchBots()
    this.fetchSystemSettings()
    this.fetchRoles()

    this.$watch(function() {
      const { showGroupDialog, dialogGroupId, fromSelection } = this
      return { showGroupDialog, dialogGroupId, fromSelection }
    }, this.toggleGroupDialog, { deep: true, immediate: true })
  },

  methods: {
    fetchUsers() {
      return this.$store.dispatch('user/getList')
    },

    fetchBots() {
      return this.$store.dispatch('botUser/getList')
    },

    fetchSystemSettings() {
      return this.$store.dispatch('service/getSystemSettings')
    },

    fetchRoles() {
      return UserRole.dispatch('$get')
    },

    closeDialog() {
      const { $router, $route } = this
      replaceRoute($router, {
        name: 'Users',
        query: R.omit(['action', 'userId', 'botId', 'groupId'], $route.query),
      })
    },

    getRoleIcon(role) {
      const roleDetails = UserRole.find(role)
      return roleDetails && roleDetails.icon
    },

    getRoleDisplayName(role) {
      const roleDetails = UserRole.find(role)
      return roleDetails && roleDetails.displayName
    },

    async setRole(user, role) {
      const { $router, currentUser } = this
      if (currentUser?.id === user.id && currentUser?.isAdmin && role !== USER_ROLE.ADMIN) {
        if (!await this.$store.dispatch('confirm/openDialog', {
          title: this.$t('user.RevokeAdminQ'),
          subtitle: this.$t('user.LoseAdminPrivilegesM'),
        })) return
        await this.updateUser(user.id, { role })
        await $router.replace('/')
      }
      return this.updateUser(user.id, { role })
    },

    userStateItems(user) {
      if (user.state === USER_STATE.PENDING.value) {
        return [USER_STATE.PENDING, USER_STATE.ACTIVE, USER_STATE._REMOVE]
      }
      return [USER_STATE.ACTIVE, USER_STATE.BLOCKED]
    },

    async setState(user, state) {
      if (state === USER_STATE._REMOVE.value) {
        if (!await this.$store.dispatch('confirm/openDialog', {
          title: this.$t('user.DeclineUserQ'),
          subtitle: this.$t('user.UserWillBeBlockedM'),
        })) return
        return this.updateUser(user.id, { state: USER_STATE.BLOCKED.value })
      }
      if (state === USER_STATE.BLOCKED.value) {
        if (!await this.$store.dispatch('confirm/openDialog', {
          title: this.$t('user.BlockUserQ'),
          subtitle: this.$t('user.UserWillNotLoginM'),
        })) return
      }
      return this.updateUser(user.id, { state })
    },

    updateUser(userId, fields) {
      const { $store } = this
      return $store.dispatch('user/update', {
        user: {
          id: userId,
          ...fields,
        },
      })
        .then(this.reloadUser)
    },

    reloadUser(userId) {
      return this.$store.dispatch('user/getDetails', { userId })
    },

    closeAllDialogs() {
      Promise.all(Object.values(this.dialogInstances)
        .filter(Boolean)
        .map(dialog => dialog.close()))
    },

    async toggleGroupDialog() {
      if (this.showGroupDialog) {
        await this.closeAllDialogs()
        const isNew = !this.dialogGroupId
        this.dialogInstances.group = await Dialog.open({
          componentName: 'UserGroupDialog',
          props: {
            userGroupId: this.dialogGroupId,
            initiallySelectedUserIds: this.fromSelection ? this.selectedUserIds : [],
          },
          listeners: {
            success: async () => {
              const isMassAction = this.selectedUserIds.length
              if (isMassAction) this.selectedUserIds = []
              await wait(300)
              this.$store.commit('$snackbar/setMessage', {
                message: isNew
                  ? this.$t('layout.SuccessfullyCreated')
                  : this.$t('layout.SuccessfullyAdded'),
                prependIcon: 'mdi-check-circle-outline',
                prependIconColor: 'darkSuccess',
                action: {
                  type: 'routerLink',
                  to: { name: 'UserGroups' },
                  exact: true,
                  label: this.$t('userGroup.GoToGroups'),
                },
                timeout: 3000,
                bottom: true,
              })
            },
          },
          onClose: () => replaceRoute(this.$router, this.closeModalRoute),
        })
      } else if (this.dialogInstances.group) {
        this.dialogInstances.group.close()
        this.dialogInstances.group = null
      }
    },

    async setGlobalMfaRequired(mfaRequired) {
      const { $store, $router } = this
      if (!await $store.dispatch('confirm/openDialog', {
        subtitle: mfaRequired
          ? this.$t('user.SecurityCodeWillBeRequiredM')
          : this.$t('user.SecurityCodeWillNoLongerRequiredForUsersM'),
        consentLabel: mfaRequired
          ? this.$t('layout.Activate')
          : this.$t('layout.Deactivate'),
        consentProps: { color: 'error', outlined: true },
      })) return
      await $store.dispatch(
        'service/setSystemSettings',
        { settings: { mfaRequired } },
      )
      if (mfaRequired && !$store.getters['user/current']?.mfaConfirmed) {
        await Promise.all([
          $store.commit('user/logOut'),
          $store.dispatch('reset'),
          $router.push({ name: 'Auth' }),
        ])
      }
    },

    checkIsUser(item) { return !item.isBot },

    addSelectionToGroup() {
      Dialog.open({
        componentName: 'UserGroupSelectDialog',
        props: {
          userIds: [...this.selectedUserIds],
        },
        listeners: {
          success: () => {
            this.selectedUserIds = []
            this.$store.commit('$snackbar/setMessage', {
              message: this.$t('layout.SuccessfullyAdded'),
              prependIcon: 'mdi-check-circle-outline',
              prependIconColor: 'darkSuccess',
              action: {
                type: 'routerLink',
                to: { name: 'UserGroups' },
                exact: true,
                label: this.$t('userGroup.GoToGroups'),
              },
              timeout: 3000,
              bottom: true,
            })
          },
        },
      })
    },

    async enableMfa(user) {
      const { $store } = this
      const { otpauthUrl, base32 } =
        await $store.dispatch('user/enableMfa', { userId: user.id })
      this.mfaDialogState = {
        userId: user.id,
        otpauthUrl,
        base32,
      }
      this.mfaDialog = true
    },

    async disableMfa(user) {
      const { $store, currentUser } = this
      try {
        if (!await $store.dispatch('confirm/openDialog', {
          title: user.id === currentUser?.id
            ? undefined // Default: Are you sure?
            : this.$t('user.DisableSecurityCodeQ'),
          subtitle: user.id === currentUser?.id
            ? this.$t('user.SecurityCodeWillNoLongerRequiredForYouM')
            : this.$t('user.UseIfAccessLostM'),
          consentLabel: this.$t('layout.Deactivate'),
          consentProps: { color: 'error', outlined: true },
        })) return
        await $store.dispatch('user/disableMfa', { userId: user.id })
      } finally {
        ++this.switchKey
      }
    },

    async resetMfa(user) {
      const { $store, currentUser } = this
      try {
        if (!await $store.dispatch('confirm/openDialog', {
          title: this.$t('user.ResetSecurityCodeQ'),
          subtitle: this.$t('user.UseIfAccessLostM'),
        })) return
        await $store.dispatch('user/disableMfa', { userId: user.id })
        if (user.id === currentUser.id) {
          const { otpauthUrl, base32 } =
          await $store.dispatch('user/enableMfa', { userId: user.id })
          this.mfaDialogState = {
            userId: user.id,
            otpauthUrl,
            base32,
          }
          this.mfaDialog = true
        }
      } catch (e) {
        console.error(e)
      } finally {
        ++this.switchKey
      }
    },

    getEditGroupRoute(groupId) {
      return {
        query: {
          ...R.omit(['userId', 'botId', 'groupId'], this.$route.query),
          action: 'edit-group',
          groupId,
        },
      }
    },

    patchListenersClickStopPrevent(listeners) {
      return {
        ...listeners,
        click: event => {
          event.preventDefault()
          event.stopPropagation()
          if (listeners.click) return listeners.click(event)
        },
      }
    },
  },
}
</script>

<style lang="sass">
@import '../scss/variables'

.Users
  &__table .VirtualScroller__scrollable
    padding-bottom: 68px !important

  &__user-groups
    overflow: hidden
    text-overflow: ellipsis

  &__user-group-link
    color: var(--v-primary-base) !important
    overflow: hidden
    text-overflow: ellipsis
    max-width: 100%
    display: inline-block
    line-height: 24px

    &:not(&--more)
      text-decoration: inherit !important

    &--more
      text-decoration: underline dotted !important

    &:hover, &:focus
      text-decoration: underline !important
</style>
